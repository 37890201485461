import React from 'react';

export default function MenuBar() {
  return (
    <div className='bg-indigo-500'>
      <div className='py-3 px-5 flex align-items-center justify-content-between relative lg:static'>
        <div className='card mr-0'>
          <p className='text-white ml-auto'>
            <i className='pi pi-desktop' style={{ fontSize: '1.5rem' }}></i>
          </p>
        </div>
        <a
          className='p-ripple cursor-pointer block lg:hidden text-indigo-50'
          href='/'
        >
          <i className='pi pi-bars text-4xl' />
          <span
            role='presentation'
            className='p-ink'
            style={{ height: 0, width: 0 }}
          />
        </a>
        {/* Menu */}
        <div className='align-items-center flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1'>
          <ul className='list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row'>
            <li>
              <a
                className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150'
                href='/'
              >
                <i className='pi pi-home mr-2' />
                <span>Home</span>
                <span role='presentation' className='p-ink' />
              </a>
            </li>
            <li>
              <a
                className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150'
                href='/'
              >
                <i className='pi pi-users mr-2' />
                <span>Student Zone</span>
                <i className='pi pi-angle-down ml-auto lg:ml-3' />
                <span role='presentation' className='p-ink' />
              </a>
            </li>
            <li>
              <a
                className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150'
                href='/'
              >
                <i className='pi pi-calendar mr-2' />
                <span>Holiday Calendar</span>
                <span role='presentation' className='p-ink' />
              </a>
            </li>
            <li>
              <a
                className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150'
                href='/'
              >
                <i className='pi pi-chart-line mr-2' />
                <span>Stats</span>
                <span role='presentation' className='p-ink' />
              </a>
            </li>
          </ul>
          <ul className='list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none'>
            <li>
              <a
                className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150'
                href='/'
              >
                <i
                  className='pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0'
                  href='/'
                />
                <span className='block lg:hidden font-medium'>Inbox</span>
                <span role='presentation' className='p-ink' />
              </a>
            </li>
            <li>
              <a
                className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150'
                href='/'
              >
                <i
                  className='pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0'
                  href='/'
                />
                <span className='block lg:hidden font-medium'>
                  Notifications
                </span>
                <span role='presentation' className='p-ink' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
