import React from 'react';
import MenuBar from './MenuBar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

export default function AppLayout() {
  return (
    <div className='min-h-screen flex flex-column'>
      <MenuBar />
      {/* Navbar  */}
      {/* Outlet */}
      <div className='flex flex-column flex-auto'>
        <div className='border-2  surface-border border-round surface-section flex-auto'>
          <Outlet />
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}
