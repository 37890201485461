import React from 'react';

export default function ToolsSoftwares() {
  return (
    <div className='surface-ground px-4 py-8 md:px-6 lg:px-8'>
      <div className='shadow-2 border-round surface-card p-4'>
        <div className='flex align-items-center justify-content-between mb-4'>
          <span className='text-xl font-medium text-900'>
            We have provide below Tools and Softwares for training.
          </span>
          <button className='p-button p-component p-button-rounded p-button-outlined p-button-icon-only'>
            <span className='p-button-icon p-c pi pi-refresh' />
            <span className='p-button-label p-c'>&nbsp;</span>
            <span
              role='presentation'
              className='p-ink'
              style={{ height: 48, width: 48 }}
            />
          </button>
        </div>
        <div className='grid'>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/word.svg'
                  alt='word'
                />
              </div>
              <span className='text-900 font-medium'>MS-Office 2021</span>
              <div className='text-600 text-sm mt-2'>520KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/excel.svg'
                  alt='excel'
                />
              </div>
              <span className='text-900 font-medium'>MS-Excel 2021</span>
              <div className='text-600 text-sm mt-2'>120KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/excel.svg'
                  alt='excel'
                />
              </div>
              <span className='text-900 font-medium'>Office 365</span>
              <div className='text-600 text-sm mt-2'>500KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/ps.svg'
                  alt='ps'
                />
              </div>
              <span className='text-900 font-medium'>Photoshop</span>
              <div className='text-600 text-sm mt-2'>220KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/atom.svg'
                  alt='atom'
                />
              </div>
              <span className='text-900 font-medium'>Atom Editor</span>
              <div className='text-600 text-sm mt-2'>10KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/code.svg'
                  alt='code'
                />
              </div>
              <span className='text-900 font-medium'>VS Code</span>
              <div className='text-600 text-sm mt-2'>10KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/ai.svg'
                  alt='ai'
                />
              </div>
              <span className='text-900 font-medium'>Adobe Illustrator</span>
              <div className='text-600 text-sm mt-2'>4.2MB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/word.svg'
                  alt='word'
                />
              </div>
              <span className='text-900 font-medium'>Office 2007</span>
              <div className='text-600 text-sm mt-2'>420KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/word.svg'
                  alt='word'
                />
              </div>
              <span className='text-900 font-medium'>Notes</span>
              <div className='text-600 text-sm mt-2'>60KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/code.svg'
                  alt='code'
                />
              </div>
              <span className='text-900 font-medium'>Web Devlopment</span>
              <div className='text-600 text-sm mt-2'>25KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/word.svg'
                  alt='word'
                />
              </div>
              <span className='text-900 font-medium'>Reports</span>
              <div className='text-600 text-sm mt-2'>120KB</div>
            </div>
          </div>
          <div className='col-12 sm:col-6 lg:col-3 xl:col-2 p-3'>
            <div className='text-center'>
              <div
                className='border-1 border-200 surface-50 flex align-items-center justify-content-center border-round mb-3 mx-auto'
                style={{ width: 90, height: 90 }}
              >
                <img
                  src='https://blocks.primereact.org/demo/images/blocks/file/ps.svg'
                  alt='ps'
                />
              </div>
              <span className='text-900 font-medium'>App & Layout</span>
              <div className='text-600 text-sm mt-2'>1.2MB</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
