import React from 'react';
import { Button } from 'primereact/button';
export default function Landing() {
  return (
    <>
      <div className='grid py-3'>
        <div className='col-12 lg:col-4'>
          <div className='surface-card border-round shadow-2 p-4 text-center'>
            <img
              src='https://blocks.primereact.org/demo/images/blocks/illustration/windows.svg'
              alt='windows'
              className='mx-auto block mb-4'
            />
            <div className='text-900 font-medium mb-2 text-xl'>
              Microsoft Office Suite & Google Suite
            </div>
            <p className='mt-0 mb-4 p-0 line-height-3'>
              Basic Computer Skills: Whether you're a beginner or looking to
              enhance your foundational knowledge, our courses cover everything
              from computer basics to internet navigation and file management.
            </p>
            <p className='mt-0 mb-4 p-0 line-height-3'>
              Microsoft Office Suite: Master essential tools like Word, Excel,
              PowerPoint, and Outlook through our tailored courses designed to
              boost your productivity and efficiency.
            </p>
            <button aria-label='Learn More' className='p-button p-component'>
              <span className='p-button-icon p-c p-button-icon-left pi pi-arrow-right p-button-rounded' />
              <span className='p-button-label p-c'>Learn More</span>
              <span
                role='presentation'
                className='p-ink'
                style={{ height: '153.062px', width: '153.062px' }}
              />
            </button>
          </div>
        </div>
        <div className='col-12 lg:col-4'>
          <div className='surface-card border-round shadow-2 p-4 text-center'>
            <img
              src='https://blocks.primereact.org/demo/images/blocks/illustration/windows.svg'
              alt='windows'
              className='mx-auto block mb-4'
            />
            <div className='text-900 font-medium mb-2 text-xl'>
              Programming and Multimedia Courses
            </div>
            <p className='mt-0 mb-4 p-0 line-height-3'>
              Programming and Development: Dive into programming languages like
              Python, JavaScript, Java, and more, and learn to create software,
              websites, and applications from scratch.
            </p>
            <p className='mt-0 mb-4 p-0 line-height-3'>
              Graphic Design and Multimedia: Unleash your creativity with
              courses in graphic design, video editing, animation, and
              multimedia tools such as Adobe Creative Suite.
            </p>
            <button aria-label='Learn More' className='p-button p-component'>
              <span className='p-button-icon p-c p-button-icon-left pi pi-arrow-right p-button-rounded' />
              <span className='p-button-label p-c'>Learn More</span>
              <span
                role='presentation'
                className='p-ink'
                style={{ height: '153.062px', width: '153.062px' }}
              />
            </button>
          </div>
        </div>
        <div className='col-12 lg:col-4'>
          <div className='surface-card border-round shadow-2 p-4 text-center'>
            <img
              src='https://blocks.primereact.org/demo/images/blocks/illustration/security.svg'
              alt='windows'
              className='mx-auto block mb-4'
            />
            <div className='text-900 font-medium mb-2 text-xl'>
              Cybersecurity & Data Science and Analytics
            </div>
            <p className='mt-0 mb-4 p-0 line-height-3'>
              Cybersecurity: Understand the fundamentals of cybersecurity, learn
              to identify threats, and implement strategies to protect against
              cyber attacks with our specialized training.
            </p>
            <p className='mt-0 mb-3 p-0 line-height-3'>
              Data Science and Analytics: Explore the world of data analysis,
              machine learning, and statistical modeling to derive meaningful
              insights and make data-driven decisions.
            </p>
            <p className='mt-0 mb-1 p-0 line-height-3'>
              Linux Server Setup: real-time hands-on lab.
            </p>
            <button aria-label='Learn More' className='p-button p-component'>
              <span className='p-button-icon p-c p-button-icon-left pi pi-arrow-right p-button-rounded' />
              <span className='p-button-label p-c'>Learn More</span>
              <span
                role='presentation'
                className='p-ink'
                style={{ height: '153.062px', width: '153.062px' }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='px-4 py-8 md:px-6 lg:px-8 flex gap-2 justify-content-center align-items-center'>
        <div className='surface-card w-6 sm:w-max border-round shadow-2 p-4'>
          <p className='text-center text-6xl font-bold'>Enroll Today!</p>
          <p className='text-center font-bold'>
            Empower yourself with the skills needed to succeed in the digital
            era. Contact us to learn more about our programs, schedules, and
            enrollment procedures. Join OCEM, Balangir, and embark on a journey
            to enhance your digital proficiency!
          </p>
          <div className='card flex justify-content-center'>
            <Button label='Click Here to enroll' />
          </div>
        </div>
      </div>
    </>
  );
}
