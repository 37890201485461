import React from 'react';

export default function Footer() {
  return (
    <>
      <div className='surface-section px-4 py-6 md:px-6 lg:px-8 text-center'>
        {/* <img
          src='https://blocks.primereact.org/demo/images/blocks/logos/bastion-purple.svg'
          alt='Image'
          height={50}
        /> */}
        <div className='card flex align-items-center justify-content-center '>
          <h2 className='text-3xl font-medium mt-1'>
            <i
              className='pi pi-desktop'
              style={{ fontSize: '5rem', color: 'var(--primary-color)' }}
            ></i>
          </h2>
        </div>
        <div className='font-medium text-900 mt-4 mb-3'>
          © 2023 Odisha Computer Education Mission, Balangir
        </div>
        <p className='text-600 line-height-3 mt-0 mb-4'>
          A leading computer training center providing courses in programming,
          web development, cybersecurity, and more.
        </p>
        <div className='flex align-items-center justify-content-center'>
          <a className='cursor-pointer text-700 mr-5'>
            <i className='pi pi-twitter' />
          </a>
          <a className='cursor-pointer text-700 mr-5'>
            <i className='pi pi-facebook' />
          </a>
          <a className='cursor-pointer text-700'>
            <i className='pi pi-github' />
          </a>
        </div>
      </div>
    </>
  );
}
