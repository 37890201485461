import AppLayout from './components/AppLayout';
import Navbar from './components/Navbar';
function App() {
  return (
    <div>
      {/* <Navbar /> */}
      <AppLayout></AppLayout>
    </div>
  );
}

export default App;
