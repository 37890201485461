import React from 'react';
import Landing from '../components/Landing';
import ToolsSoftwares from '../components/ToolsSoftwares';

export default function Home() {
  return (
    <>
      <Landing />
      <ToolsSoftwares />
    </>
  );
}
